import Vue from 'vue'
import App from './App.vue'
import router from './router'
import mitt from 'mitt'
import utils from './utils'
import i18n from './locale'
import api from './api/api'
// import './plugins/element'
import './styles/index.less'
import { Col, Row, Badge, GoodsAction, GoodsActionIcon, GoodsActionButton,Overlay } from 'vant';
import 'vant/lib/index.css'
import '@vant/touch-emulator';

Vue.use(Overlay);
Vue.use(Badge);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Col);
Vue.use(Row);
Vue.use(Overlay);

// Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$bus = mitt()
Vue.prototype.$utils = utils
Vue.prototype.$api = api

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
